<template>
  <div class="login-screen sys-screen">
    <div class="center-block">
      <div class="positioning">
        <div class=" vertical-10p">
          <img
            src="../../../../public/static/assets/img/logo.svg"
            class="center-block logo logo-login"
          >
          <div class="text-center">
            <!-- Main content -->
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.vertical-10p {
    padding: 10%;
    border-radius: 2px;
    border: 1px solid #2d3446;
    -webkit-box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
}

.logo-login {
    margin-bottom: 15px;
}
</style>
